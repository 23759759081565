
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';


import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
 
    return (

   
<Grid>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <h1 className="a11">Class Materials</h1>
    <h2 className="a11">Spanish For Business A2.2 Level</h2>
  
    <div class="center"> 
        <Button href='https://spanish-business-a22-unit2.comligospanish.com/' className="btn-a22-unit2" >A2.2 Unit 2</Button>   
       
        {/* <Button href='' download className="btn-download" size='medium'>Beginner 2</Button>  */} 
    </div>
    <div class="center"> 
    <Button href='https://spanish-business-a22-unit3.comligospanish.com/'  className="btn-a22-unit3" >A2.2 Unit 3</Button> 
    </div>
    <div class="center"> 
    <Button href='https://spanish-business-a22-unit4.comligospanish.com/'  className="btn-a22-unit4" >A2.2 Unit 4</Button> 
    </div>
   
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
      //   </header>
      //   </div>
      // </div>
    );
  }
}
