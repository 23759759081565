import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';

// import '../../style.scss';
import './B11U1L6.scss';

export default class B11U1L6 extends Component {
  render() {
   
    return (

<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <h1 className="b11">Class Materials</h1>
    <h2 className="b11">General Spanish B1.4 Level</h2>    
  
    <div class="center"> 
        <Button href='https://spanish-business-b14-unit1.comligospanish.com/' className="btn-b14-unit1" >B1.4 Unit 1</Button>   
       
        {/* <Button href='' download className="btn-download" size='medium'>Beginner 2</Button>  */} 
    </div>
    <div class="center"> 
    <Button href='https://spanish-business-b14-unit2.comligospanish.com/'  className="btn-b14-unit2" >B1.4 Unit 2</Button> 
    </div>
    <div class="center"> 
    <Button href='https://spanish-business-b14-unit3.comligospanish.com/'  className="btn-b14-unit3" >B1.4 Unit 3</Button> 
    </div>
    {/* <div class="center"> 
    <Button href='https://generalspanish-a23-unit4.web.app/'  className="btn-b11-unit4" >B1.1 Unit 4</Button> 
    </div> */}
 <div class="center"> 
    <Button href='https://spanish-business-b14-unit4.comligospanish.com/'  className="btn-b14-unit4" >B1.4 Unit 4</Button> 
    </div>
    <div class="center"> 
    <Button href='https://spanish-business-b14-unit5.comligospanish.com/'  className="btn-b14-unit5" >B1.4 Unit 5</Button> 
    </div>
    <div class="center"> 
    <Button href='https://spanish-business-b14-unit6.comligospanish.com/'  className="btn-b14-unit6" >B1.4 Unit 6</Button> 
    </div>
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

  
    );
  }
}


    